<template>
    <div id="wrapper" class="stu_index">
        <el-container>
            <!-- 头部 -->
            <el-header height="50px">
                <div class="tea_header">
                    <div class="tea_header_l">
                        <span>ICD创意价值设计 —— 学员端</span>
                    </div>
                    <div class="tea_header_sr clearfix">
                        <div class="menu_one fl">
                            <el-tooltip class="item" effect="dark" content="资料库" placement="bottom">
                                <i class="iconfonts icons-icon_folder" @click="drawers()"></i>
                            </el-tooltip>
                        </div>
                        <div class="u-avatar fr" @mouseover="mouseover()" @mouseout="mouseout()">
                            <div class="fl member">
                                <img class="avatar fl" :src="this.$store.state.user_avatar===null ||this.$store.state.user_avatar==''||this.$store.state.user_avatar=='null'?'https://static.ibeisai.com/image/default_avatar.png':this.$store.state.user_avatar" alt="">                    
                            </div>
                            <p class="name fl">
                                <span>{{this.$store.state.user_name===null||this.$store.state.user_name===''||this.$store.state.user_name==='null'?'':this.$store.state.user_name}}</span>
                            </p>
                            <ul class="dropdown-menu" v-show="login_acc==true">
                                <li @click="count()">账号设置</li>
                                <li @click="exits()">安全退出</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-container class="conBar">
                <!-- 左侧信息 -->
                <el-aside width="240px">
                    <div class="l_nav">
                        <div class="l_title"  @click="backss()">
                            <i class="iconfonts icons-shouyefill"></i>
                            <span>返回首页</span>
                        </div>
                        <div class="l_item">
                            <div class="item_kc">
                                <span>课程详情</span>
                            </div>
                            <ul class="l_code">
                                <li>
                                    <span class="l_code_span">班级</span>：{{form.course.grade}}
                                </li>
                                <li>
                                    <span class="l_code_span">课堂名称</span>：{{form.course.name}}
                                </li>
                                <li>
                                    <span class="l_code_span">课程码</span>：{{form.course.code}}
                                </li>
                            </ul>
                            <div class="item_kc">
                                <span>{{form.team}}</span>
                            </div>
                                <ul class="item_ul">
                                    <li v-for="(item,index) in form.users" :key="index" @mouseover="usersover(index)" @mouseout="usersout()">
                                        <i class="iconfonts icons-ic_exchanged" 
                                            v-show="item.type==1 && form.leader==true && userNum == index" @click="excfBox(item)" title="转让组长">
                                        </i>
                                        <i class="el-icon-close members" 
                                            v-show="item.type==0 && form.leader==true && userNum == index" @click="membBox(item)" title="移除组员">
                                        </i>
                                        <span class="z_span" v-show="item.type==1">组长</span>
                                        <span class="z_spans" v-show="item.type==0"></span>
                                        <img :src="item.avatar" alt="">
                                        <span :title="item.name">{{item.name}}</span>
                                    </li>
                                </ul>
                            <div class="item_kc" v-show="this.active!==9">
                                <span>参考资料</span>
                            </div>
                            <ul class="Refercase" v-show="this.active!==9">
                                <li class="one_case" @click="videoBox(1)">
                                    <i class="iconfonts icons-shipin"></i>
                                    <p class="case_name">参考资料-视频</p>
                                </li>
                                <li  class="two_case" @click="videoBox(2)">
                                    <i class="iconfonts icons-file-ppt-fill"></i>
                                    <p class="case_name">参考资料-PPT</p>
                                </li>
                                <li class="three_case" @click="videoBox(3)">
                                    <i class="iconfonts icons-file-word-fill"></i>
                                    <p class="case_name">参考资料-Word</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-aside>
                <!-- 右侧内容 -->
                <el-container>
                    <el-main>
                        <div class="main_div">
                            <div class="step_div">
                                <ul class="step_div_steps">
                                    <li v-for="(item,index) in stepsList" :key="index">
                                        <div class="bor_div" @click="stepBox(item,item.id)" :class="item.state!=0?'actice_div':''">
                                            <div class="bor_div_top" :class="active==item.id?'bor_div_progre':''">
                                                <el-progress type="circle" 
                                                    :percentage="item.state!=0 && item.id == 1?11.1:item.state!=0 && item.id == 2?22.2:item.state!=0 && item.id == 3?33.3:item.state!=0 && item.id == 4?44.4:item.state!=0 && item.id == 5?55.5:item.state!=0 && item.id == 6?66.6:item.state!=0 && item.id == 7?77.7:item.state!=0 && item.id == 8?88.8:item.state!=0 && item.id == 9?100:0" 
                                                    :stroke-width="8" 
                                                    :show-text='false' 
                                                    :color="'#54A9D8'"
                                                ></el-progress>
                                                <i class="iconfonts" :class="item.icon"></i>
                                            </div>
                                            <div class="bor_div_title" :class="active==item.id?'acti_title':''">
                                                {{item.name}}
                                            </div>
                                        </div>
                                        <div class="bor_div_r" :class="item.state!=0?'acti_div_r':''" v-show="index!= stepsList.length - 1">
                                            <div class="one_r">

                                            </div>
                                            <div class="two_r">

                                            </div>
                                            <div class="two_rs">

                                            </div>
                                            <div class="three_r">
                                                <i class="el-icon-arrow-right"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="content_div">
                                <router-view @info="valuess" :searchVals="searchVals"></router-view>
                            </div>
                        </div>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
        <!-- 弹窗 -->
        <div class="pdf_popup">
            <el-dialog
                title="查看详情"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose"
                :show-close="false"
                top="10vh"
            >
            <i class="el-icon-close closei" @click="closei()"></i>
                <div class="cd_video" v-if="videonum==1">
                    <video-player
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
                        @play="onPlayerPlay($event)"
                        @pause="onPlayerPause($event)"
                        @ended="onPlayerEnded($event)"
                    ></video-player>
                </div>
                <div class="pdf_div" v-if="videonum==2 || videonum==3">
                    <el-button-group>
                        <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="prePage">上一页</el-button>
                        <el-button type="primary" size="mini" @click="nextPage">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-button-group>
                    <div style="marginTop: 10px; color: #409EFF">{{ pageNum }} / {{ pageTotalNum }}</div>
                    <img :src="item" v-for="(item,index) in imgList" :key="index" v-show="pageNum == index + 1 " alt="">
                    <!-- <pdf
                        :page="pageNum"
                        :src="url"
                        @progress="loadedRatio = $event"
                        @num-pages="pageTotalNum=$event" 
                    ></pdf> -->
                </div>
                <div class="photo_div">
                    <img :src="imageUrl" alt="">
                </div>
            </el-dialog>
            <el-dialog
                title="组长转让"
                :visible.sync="dialogVisibleForm"
                :show-close="false"
                width="450px"
                :before-close="handleClose">
                    <i class="el-icon-close closei" @click="closeis('Form')"></i>
                    <el-form ref="Form" :model="Form" :rules="rules" size="small" :inline='true' @submit.native.prevent>
                        <el-form-item label="选择组长" prop="uid">
                            <el-select v-model="Form.uid" placeholder="请选择组长">
                                <el-option
                                    v-for="(item,index) in listTeam"
                                    :key="index"
                                    :label='item.name'
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="small" @click="closeis('Form')">取 消</el-button>
                        <el-button size="small" type="primary" @click="deter('Form')">确 定</el-button>
                    </span>
            </el-dialog>
        </div>
        <!-- 资源库抽屉 -->
        <div class="drawerBox">
            <el-drawer
                size="450px"
                title="资料库"
                :visible.sync="drawer"
                :with-header="true">
                <div class="mu_list">
                    <ul>
                        <li v-for="(item,index) in drawList" :key="index" v-show="drawList.length!=0">
                            <div class="bm-drake-box">
                                <div class="mu-item">
                                    <div class="mu-item-left">
                                        <div class="mu-avatar">
                                            <div class="mu-avatar-inner">
                                                <img :src="item.thumb" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mu-item_item clearfix">
                                        <div class="item_left fl">
                                            <span @click="viewBox(item)">{{item.name}}</span>
                                        </div>
                                        <div class="item_right fr">
                                            <i class="iconfonts icons-xiazai" @click="download(item)"></i>
                                        </div>
                                    </div>
                                    <hr class="mu-divider">
                                </div>
                            </div>
                        </li>
                        <div class="default-wrapper" v-show="drawList.length==0">
                            <img src="./../../assets/img/bookmark.svg" alt="">
                            <p><span>暂无数据</span></p>
                        </div>
                    </ul>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import pdf from 'vue-pdf'
export default {
    components: {
        videoPlayer,
        pdf
    },
    data() {
        return {
            userNum:-1,
            Form:{
                uid:''
            },
            rules:{
                uid:{ required: true, message: '请选择组长', trigger:'change'}
            },
            listTeam:[],
            dialogVisibleForm:false,
            imgList:[],
            drawer:false,
            login_acc:false,
            searchVals:'',
            imageUrl:'',
            videonum:0,
            drawList:[],
            sourc:{
                pdf:{},
                video:{},
                word:{}
            },
            form:{
                course:{},
                team:'',
                users:[]
            },
            stepsList:[],
            teamList:[],
            dialogVisible:false,
            active:0,
            url: '',
            pageNum: 1,
            pageTotalNum: 1, // 总页数
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                width: "100%",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: "video/mp4", // 类型
                        src: "" // url地址
                    }
                ],
                poster: '', // 封面地址
                notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        };
    },
    methods: {
        getapp(){
            this.axios.StuCourseinfo({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.$store.dispatch("team_id",res.data.data.users[0].tId)
                    this.stepsApp()
                }else{
                    this.$message.error(res.data.message)
                    this.form = []
                }
            }).catch(err=>{

            })
        },
        stepsApp(){
            this.axios.StuCoursestep({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.stepsList = res.data.data.steps
                    this.searchVals = res.data.data.id
                }else{
                    this.$message.error(res.data.message)
                    this.stepsList = []
                }
            }).catch(err=>{

            })
        },
        valuess(data){
            this.sourc = data
        },
        //鼠标移入
        usersover(val){
            this.userNum = val
        },
        usersout(){
            this.userNum = -1
        },
        excfBox(item){
            this.listTeam = this.form.users.filter(infos => infos.id != item.id)
            this.dialogVisibleForm = true
        },
        deter(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.courselead({
                        token:this.$store.state.token,
                        id:this.$store.state.cla_id,
                        uid:this.Form.uid
                    }).then(res=>{
                        if(res.data.code==0){
                            this.getapp()
                            this.$message.success('转让成功')
                            this.dialogVisibleForm = false
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.message)
                        }
                        
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除组员
        membBox(item){
            this.$confirm('是否移除该组员？', '确定移除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.courseremove({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    uid:item.id
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消移除'
                });          
            });
        },
        closeis(formName){
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        },
        // 浏览PPT、word、视频
        videoBox(val){
            this.pageNum = 1
            this.dialogVisible = true
            this.videonum = val
            if(val == 1){
                this.playerOptions.poster = this.sourc.video.img;
                this.playerOptions.sources[0].src = this.sourc.video.url;
            }else if(val == 2){
                // this.url = this.sourc.pdf.url
                this.imgList= this.sourc.pdf.vas
                this.pageTotalNum = this.imgList.length
            }else if(val == 3){
                // this.url = this.sourc.word.url
                this.imgList= this.sourc.word.vas
                this.pageTotalNum = this.imgList.length
            }
        },
        stepBox(item,val){
                this.axios.StuCoursenext({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    step:val
                }).then(res=>{
                    if(res.data.code==0){
                        this.searchVals = res.data.data.id
                        res.data.data.steps.map(items=>{
                            if(items.id == val){
                                this.saa = items.state
                            }
                        })
                        if(this.saa==0){
                            this.$message.warning('此环节还未开启')
                        }else{
                            this.active = val
                            if(val==1){
                                this.$router.push({name:'Cognitivecomb'})
                            }else if(val==2){
                                this.$router.push({name:'Datacollection'})
                            }else if(val==3){
                                this.$router.push({name:'Refactorinfocus'})
                            }else if(val==4){
                                this.$router.push({name:'Derlyconstruct'})
                            }else if(val==5){
                                this.$router.push({name:'Sketchtodescribe'})
                            }else if(val==6){
                                this.$router.push({name:'Modelmaking'})
                            }else if(val==7){
                                this.$router.push({name:'Exercisetesting'})
                            }else if(val==8){
                                this.$router.push({name:'Feamization'})
                            }else if(val==9){
                                this.$router.push({name:'Minimaering'})
                            }
                            this.getapp()
                        }
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
        },
        // 上一页
        prePage() {
            let page = this.pageNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pageNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pageNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pageNum = page
        },
        // 播放回调
        onPlayerPlay(player) {
            console.log("player play!", player);
        },
        // 暂停回调
        onPlayerPause(player) {
            console.log("player pause!", player);
        },
        // 视频播完回调
        onPlayerEnded($event) {
            console.log(player);
        },
        closei() {
            this.dialogVisible = false;
            this.playerOptions.sources[0].src = '';
            this.url = ''
            this.videonum = 0
        },
        handleClose(done){

        },
        // 打开资料库
        drawers(){
            this.axios.StuCoursedoc({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.drawList = res.data.data
                    this.drawer = true
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        backss(){
            this.$router.push({name:'Myclass'})
        },
        //退出登录
        exits(){
            this.axios.userlogout({
                token:this.$store.state.token
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push('/login')
                    localStorage.clear()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 高亮
        getPath(){
            if(this.$route.name == 'Cognitivecomb'){
                this.active=1
            }else if(this.$route.name == 'Datacollection'){
                this.active=2
            }else if(this.$route.name == 'Refactorinfocus'){
                this.active=3
            }else if(this.$route.name == 'Derlyconstruct'){
                this.active=4
            }else if(this.$route.name == 'Sketchtodescribe'){
                this.active=5
            }else if(this.$route.name == 'Modelmaking'){
                this.active=6
            }else if(this.$route.name == 'Exercisetesting'){
                this.active=7
            }else if(this.$route.name == 'Feamization'){
                this.active=8
            }else if(this.$route.name == 'Minimaering'){
                this.active=9
            }
        },
        mouseover(){
            this.login_acc = true
        },
        mouseout(){
            this.login_acc = false
        },
        // 资料库预览
        viewBox(item){
            this.pageNum = 1
            this.dialogVisible = true
            if(item.type==1){
                this.videonum = 2
                this.url = item.url
            }else if(item.type==2){
                this.videonum = 4
                this.imageUrl = item.url
            }else if(item.type==3){
                this.videonum = 1
                this.playerOptions.sources[0].src = item.url;
            }
        },
        // 资料库下载
        download(item){
            window.open(item.url, "_blank");
        },  
        count(){
            this.$router.push({name:'StudentSetting'})
        }
    },
    watch:{
        $route(to,from){
            this.getPath()
        }
    },
    beforeDestroy () {
        let that = this;
        clearInterval(that.timer);
        that.timer = null;
    },
    destroyed () {
        let that = this;
        clearInterval(that.timer);
        that.timer = null;
    },
    mounted() {
        this.getapp()
        this.getPath()
        let that = this;
        that.timer = window.setInterval(() => {
            setTimeout(() => {
                that.stepsApp()
            }, 0)
        },3000)
    }
};
</script>
<style scoped>
@import './../../assets/css/student/index.css';
.video-player{
    width: 100%;
    height: 100%;
}
</style>
<style>
.bor_div_top .el-progress{
    position: absolute;
    transform: rotate(180deg);
}
.bor_div_top .el-progress-circle{
    width: 50px!important;
    height: 50px!important;
}
/* .bor_div_progre{
    background: #54A9D8;
    border-radius: 50%;
} */
/* .bor_div_progre i{
    color: #fff!important;
} */
/* 资源库抽屉 */
.drawerBox .el-drawer__header{
    padding: 0 8px;
    height: 44px;
    line-height: 44px;
    background: #f1f6fe;
    color: #0e1726;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.stu_index .el-header{
    background-color: #ffffff;
}
.stu_index .el-aside{
    background-color: #fff;
}
.stu_index .el-main{
    padding: 0 20px 0px;
}
.stu_index .el-footer{
    padding: 0 20px;
}
.el-step__icon{
    width: 20px;
    height: 20px;
    font-size:12px;
}
.el-step__title{
    font-size:14px
}


/* 弹窗 */
.pdf_popup .el-dialog__footer{
    text-align: center;
}
.pdf_popup .el-form{
    text-align: center;
}
.pdf_popup .el-dialog__header {
   padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.pdf_popup .el-dialog__body{
    background-color: #fff;
}
.pdf_popup .el-dialog__title {
  font-size: 16px;
    font-weight: bold;
    color:#fff;
}
.pdf_popup .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
.pdf_popup .el-dialog__headerbtn {
  top: 15px;
}
.cd_video .video-js{
    height: 100%!important;
}
.cd_video .video-js .vjs-mute-control{
    padding-left: 0;
}
.cd_video .video-js .vjs-control{
    width: 2.5em;
}
</style>